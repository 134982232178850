// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-pages-404-mdx": () => import("./../../../content/pages/404.mdx" /* webpackChunkName: "component---content-pages-404-mdx" */),
  "component---content-pages-impressum-md": () => import("./../../../content/pages/impressum.md" /* webpackChunkName: "component---content-pages-impressum-md" */),
  "component---content-pages-index-mdx": () => import("./../../../content/pages/index.mdx" /* webpackChunkName: "component---content-pages-index-mdx" */),
  "component---content-pages-work-process-mdx": () => import("./../../../content/pages/work-process.mdx" /* webpackChunkName: "component---content-pages-work-process-mdx" */),
  "component---src-gatsby-theme-catalyst-blog-components-queries-post-list-query-js": () => import("./../../../src/gatsby-theme-catalyst-blog/components/queries/post-list-query.js" /* webpackChunkName: "component---src-gatsby-theme-catalyst-blog-components-queries-post-list-query-js" */),
  "component---src-gatsby-theme-catalyst-blog-components-queries-post-query-js": () => import("./../../../src/gatsby-theme-catalyst-blog/components/queries/post-query.js" /* webpackChunkName: "component---src-gatsby-theme-catalyst-blog-components-queries-post-query-js" */)
}

