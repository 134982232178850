/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import SectionWrapper from "./section-wrapper"
import SectionHeader from "./section-header"

const SiteSection = () => {
  const data = useStaticQuery(graphql`
    query {
      contactImage1: file(relativePath: { eq: "musicartsgraz-logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <SectionWrapper id="contact">
      <SectionHeader>Contact</SectionHeader>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
          gridTemplateRows: "auto",
          gridGap: 4,
          maxWidth: "1024px",
          margin: "0 auto",
        }}
      >
        <div
          sx={{
            gridColumn: ["1 / -1", "2 / 6", null, null, null],
            gridRow: "1 / 2",
            zIndex: 5,
          }}
        >
          <Styled.h3>Management | MusicArts Graz</Styled.h3>

          <div>
            <a href="http://www.musicartsgraz.at/">
              http://www.musicartsgraz.at/
            </a>
            <a
              href="https://www.google.com/maps/search/Frankensteingasse+7,+Graz,+8047+Austria"
              target="_blank"
              rel="noopener noreferrer"
              title="Open in Google Maps"
            >
              <Styled.div>Frankensteingasse 7, Graz, 8047, Austria</Styled.div>
            </a>
            <Styled.div>
              <a href="mailto:info@musicartsgraz.at">info@musicartsgraz.at</a>
            </Styled.div>

            <Styled.div>
              <a href="tel:+436763412166">+43 676 3412166</a>
            </Styled.div>
          </div>
        </div>
        <Img
          sx={{
            gridColumn: ["1 / -1", "1 / 3", null, null, null],
            gridRow: "1 / 2",
            opacity: ["0.05", "1", null, null, null],
            filter: "none",
          }}
          fluid={data.contactImage1.childImageSharp.fluid}
          alt="MusicArts Graz"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
    </SectionWrapper>
  )
}

export default SiteSection
