/** @jsx jsx */
import React from "react"
import { jsx, Styled } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import SectionWrapper from "./section-wrapper"
import SectionHeader from "./section-header"

const SiteSection = () => {
  const data = useStaticQuery(graphql`
    query {
      klaus: file(relativePath: { eq: "artist-klaus.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      irma: file(relativePath: { eq: "artist-irma.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      irmtraud: file(relativePath: { eq: "artist-irmtraud.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lana: file(relativePath: { eq: "artist-lana.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      barbara: file(relativePath: { eq: "artist-barbara.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <SectionWrapper id="artists">
      <SectionHeader>Artists</SectionHeader>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateRows: "auto",
          gridGap: 4,
          maxWidth: "1024px",
          margin: "0 auto",
          pt: 2,
        }}
      >
        <Artist
          name="Klaus Eberle"
          instrument="Violin 1"
          country="Austria"
          image={data.klaus.childImageSharp.fluid}
        >
          Our man of contrasts; correct and quirky. Lives on music and energy
          drinks. Among many other things, sound engineer, videographer, editor,
          tune arrangeur, rehearsal manager, ideas man, lighting technician,
          chauffeur, percussion setter, melodica tuner, set designer, script
          writer, set designer, moderator, sounding board, chief commiserator,
          cat whisperer… and, of course, violinist extraordinaire.
        </Artist>
        <Artist
          name="Barbara Upelj"
          instrument="Violin 2"
          country="Slovenia"
          image={data.barbara.childImageSharp.fluid}
        >
          <p>
            STOP! Do not make her laugh, or rehearsal will be delayed by 20
            minutes, minimum!{" "}
          </p>

          <p>Our laugh-aholic, bongo specialist and coffee addict.</p>

          <p>
            An expert on presence, she is our costume and makeup artist with
            intense attention to detail. She finds humor in both music and life
            and loves making people laugh. Barbara can under no circumstances
            stand still and not even a broken foot can stop her from dancing
            away a night or two. She is usually the one to bring crazy ideas to
            Irmtraud, and, determined as she is, won't stop until they are
            realized.
          </p>
        </Artist>
        <Artist
          name="Irma Servatius"
          instrument="Viola"
          country="United States of America"
          image={data.irma.childImageSharp.fluid}
        >
          <p>
            Since still waters run deep, she has the ability of saying it all
            just with her eyes. A very talented hand-held natural-light
            photographer and out-of-the-box thinker, always offering another
            perspective and bringing creative ideas to the table. Has an eye for
            finding those special hidden moments that the rest of us miss and
            capturing them with her camera. Irma has a huge knowledge of old and
            new movies, books, music and viola jokes, which she delivers
            perfectly.
          </p>
        </Artist>
        <Artist
          name="Lana Beraković"
          instrument="Cello"
          country="Croatia"
          image={data.lana.childImageSharp.fluid}
        >
          <p>Coming soon...</p>
        </Artist>
        <Artist
          name="Irmtraud Eberle-Härtl"
          instrument="Double Bass"
          country="Austria"
          image={data.irmtraud.childImageSharp.fluid}
        >
          <p>
            Her secret superpower is the ability to sing one melody while
            playing something completely different. Our chief diplomat,
            visionary heart and an expert multi-tasker. Direct and clear, she
            leaves no musical stone unturned and is the bass-is of the quintet
            as well as the rhythmic driver. Irmtraud intuitively knows how
            people around her are feeling and is always ready to offer a
            sympathetic ear, a shoulder to cry on and has an infinite supply of
            remedies in the form of coffee, champagne or sugar on hand. But do
            not come to her with any crazy ideas, because she WILL bring them to
            life! She understands how to add the right spices in the right
            amount to life and to her perfect cooking.
          </p>
        </Artist>
      </div>
    </SectionWrapper>
  )
}

export default SiteSection

const Artist = ({ name, instrument, country, image, children }) => {
  return (
    <>
      <Img
        sx={{
          width: ["150px", "200px", "200px", null, null],
          height: "100%",
          justifySelf: "center",
          gridColumn: ["1 / -1", null, "1 / 3", null, null],
        }}
        fluid={image}
        alt={name}
        imgStyle={{ objectFit: "contain" }}
      />
      <div
        sx={{
          gridColumn: ["1 / -1", null, "3 / -1", null, null],
        }}
      >
        <Styled.h1 sx={{ mt: 0 }}>
          {name}, {instrument}
        </Styled.h1>
        <Styled.p>({country})</Styled.p>
        {children}
      </div>
    </>
  )
}
