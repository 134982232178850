/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { useContext } from "react"
import {
  HomeContext,
  useCatalystConfig,
  useSiteMetadata,
} from "gatsby-theme-catalyst-core"
import { animateScroll as scroll } from "react-scroll"

const SiteLogo = () => {
  const [isHome] = useContext(HomeContext)
  const { title, logo } = useSiteMetadata()
  const { displaySiteLogo, displaySiteLogoMobile } = useCatalystConfig()
  // Set a value for laptop display of logo
  const displayLaptop = () => {
    if (displaySiteLogo) {
      return "block"
    } else {
      return "none"
    }
  }
  // Set a value for mobile display of logo
  const displayPhone = () => {
    if (displaySiteLogoMobile) {
      return "block"
    } else {
      return "none"
    }
  }
  if (isHome) {
    return (
      <div
        sx={{
          display: [displayPhone, null, displayLaptop, null, null],
          cursor: "pointer",
        }}
        onClick={() => {
          scroll.scrollToTop()
        }}
        onKeyPress={() => {
          scroll.scrollToTop()
        }}
        role="button"
        tabIndex="0"
        aria-label="Scroll to top"
      >
        <Img
          sx={{
            height: [
              theme => theme.sizes.logoHeightXS,
              theme => theme.sizes.logoHeightS,
              theme => theme.sizes.logoHeightM,
              theme => theme.sizes.logoHeightL,
              theme => theme.sizes.logoHeightXL,
            ],
            width: [
              theme => theme.sizes.logoWidthXS,
              theme => theme.sizes.logoWidthS,
              theme => theme.sizes.logoWidthM,
              theme => theme.sizes.logoWidthL,
              theme => theme.sizes.logoWidthXL,
            ],
            mr: 2,
            filter: "none",
            variant: "variants.siteLogo",
          }}
          fluid={logo}
          alt={title}
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
    )
  } else {
    return (
      <Link to="/" onClick={() => {}} sx={{ textDecoration: "none" }}>
        <Img
          sx={{
            display: [displayPhone, null, displayLaptop, null, null],
            height: [
              theme => theme.sizes.logoHeightXS,
              theme => theme.sizes.logoHeightS,
              theme => theme.sizes.logoHeightM,
              theme => theme.sizes.logoHeightL,
              theme => theme.sizes.logoHeightXL,
            ],
            width: [
              theme => theme.sizes.logoWidthXS,
              theme => theme.sizes.logoWidthS,
              theme => theme.sizes.logoWidthM,
              theme => theme.sizes.logoWidthL,
              theme => theme.sizes.logoWidthXL,
            ],
            mr: 2,
            filter: "none",
            variant: "variants.siteLogo",
          }}
          fluid={logo}
          alt={title}
          imgStyle={{ objectFit: "contain" }}
        />
      </Link>
    )
  }
}

export default SiteLogo
