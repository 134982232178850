import News from "../../../../src/components/home-page/recent-writing";
import About from "../../../../src/components/home-page/about";
import Artists from "../../../../src/components/home-page/artists";
import Contact from "../../../../src/components/home-page/contact";
import Music from "../../../../src/components/home-page/music";
import Hero from "../../../../src/components/home-page/hero";
import * as React from 'react';
export default {
  News,
  About,
  Artists,
  Contact,
  Music,
  Hero,
  React
};