/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import SectionWrapper from "./section-wrapper"
import SectionHeader from "./section-header"

const SiteSection = () => {
  const data = useStaticQuery(graphql`
    query {
      musicImage: file(relativePath: { eq: "music.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <SectionWrapper id="music">
      <SectionHeader>Music</SectionHeader>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 2fr",
          gridTemplateRows: "1fr",
          gridGap: 4,
          maxWidth: "1024px",
          margin: "0 auto",
          pt: 2,
          justifySelf: "center",
        }}
      >
        <div
          sx={{
            gridColumn: [null, "1 / -1", null, null, null],
            justifySelf: "center",
          }}
        >
          <a href="https://youtube.com/unitedintonations">
            <Img
              sx={{
                width: ["250px", "300px", "100%", null, null],
                height: "100%",
                justifySelf: "center",
                gridColumn: ["1 / -1", null, "3 / -1", null, null],
                gridRow: ["1 / 2", null, "1 / 2", null, null],
                filter: "none",
              }}
              fluid={data.musicImage.childImageSharp.fluid}
              alt="United Intonations"
              imgStyle={{ objectFit: "contain" }}
            />
          </a>
          Please subscribe, like, click, comment, watch, listen, and enjoy!
        </div>
      </div>
    </SectionWrapper>
  )
}

export default SiteSection
