/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import SectionWrapper from "./section-wrapper"
import SectionHeader from "./section-header"

const SiteSection = () => {
  return (
    <SectionWrapper id="about">
      <SectionHeader>About Us</SectionHeader>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          maxWidth: "1024px",
          margin: "0 auto",
        }}
      >
        <Styled.p>
          5 musicians from 4 different nations playing on 10+ instruments to
          bring the music that they love to your ears. A string quintet with a
          twist, they break boundaries between musical genres, play music they
          like and bring their classical chamber ensemble to another level.
          These four ladies and a gentleman crossed paths in Graz, Austria and
          formed a unique group that is not only musically interesting, but also
          visually engaging and self-sufficient. They make all of the audio
          recordings themselves, produce their own videos, play their own
          exclusive arrangements, written by them or for them, and take care of
          management and organization. In doing so they formed a tight bond that
          is highly noticeable in their musical interpretation and in their
          stage presence.
        </Styled.p>
        <Styled.p>
          The main musical focus of the group lies in bringing quality
          interpretations of music such as operetta, Viennese tradition,
          musicals and film music to listeners, as well as arrangements of
          popular rock, jazz and pop hits. The “classical” portion of their
          repertoire includes beloved melodies from baroque all the way to the
          20th century as well as pieces written especially for them. Concert
          programs with United Intonations include an interesting mix between
          old and new, combinations of different genres and stories behind the
          music and composers so there is a hidden gem in there for everyone.
        </Styled.p>
      </div>
    </SectionWrapper>
  )
}

export default SiteSection
